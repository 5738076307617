import '../sass/services.scss';

(() => {
    const $services = document.querySelectorAll('.services');

    if (!$services) return;

    const lottieAnimations = {
        'service-explore': {
            path: GLOBALS?.TEMPLATE_DIRECTORY + '/assets/lottie/explore.json',
            initialSegment: 184,
            totalFrames: 211,
        },
        'service-create': {
            path: GLOBALS?.TEMPLATE_DIRECTORY + '/assets/lottie/create.json',
            initialSegment: 111,
            totalFrames: 209,
        },
        'service-deploy': {
            path: GLOBALS?.TEMPLATE_DIRECTORY + '/assets/lottie/deploy.json',
            initialSegment: 201,
            totalFrames: 275,
        },
        'service-scale': {
            path: GLOBALS?.TEMPLATE_DIRECTORY + '/assets/lottie/scale.json',
            initialSegment: 125,
            totalFrames: 211,
        }
    };

    $services.forEach($services => {
        const $serviceCards = $services.querySelectorAll('.services__service');

        const initServiceIcons = () => {
            if (!$serviceCards) return;

            $serviceCards.forEach($serviceCard => {
                const $iconAnimation = $serviceCard.querySelector('.services__service-icon-animation');

                const lottieAnimation = lottieAnimations?.[$iconAnimation?.dataset?.icon];

                if (!lottieAnimation) return;

                const serviceIcon = lottie.loadAnimation({
                    container: $iconAnimation,
                    renderer: 'svg',
                    loop: false,
                    autoplay: false,
                    path: lottieAnimation?.path,
                });

                serviceIcon.goToAndStop(lottieAnimation?.initialSegment, true);

                let hover = false;

                $serviceCard.addEventListener('mouseenter', () => {
                    hover = true;
                    
                    if (!serviceIcon?.isPaused) return;
                    
                    serviceIcon.playSegments([[serviceIcon?.currentFrame, lottieAnimation?.totalFrames], [0, lottieAnimation?.initialSegment]], true);
                });

                $serviceCard.addEventListener('mouseleave', () => {
                    hover = false;
                });
            });
        };

        initServiceIcons();
    });
})();
